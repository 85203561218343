import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import "../styles/Navbar.css";
import logo from "../assets/cara.png";
import { Link } from 'react-router-dom';
import { users } from '../productsStore/UserStore';
import { AuthContext } from '../App';

import {
    Container,
    TextField,
    Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(8),
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(5),
    },
    textField: {
        marginBottom: theme.spacing(5),
        
    },
    submitButton: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginPage = () => {
    const { login, currentUser } = useContext(AuthContext);
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Email:', email);
        console.log('Password:', password);
            // users.find(user => {
            //     if (user.email === email && user.password === password) {
            //         login(user);
            //         if (currentUser !== null) {
            //             navigate("/");
            //         } else {
            //             setLoginError(true);
            //         }
            //     }
            // });
            const loggedIn = await login(email, password);
            console.log(loggedIn);
            if (loggedIn) {
                navigate("/");
            } else {
                setLoginError(true);
            
            }
            // users.find(async user => {
            //     if (user.email === email && user.password === password) {
            //         const loggedIn = await login(email, password);
            //         console.log(loggedIn);
            //         if (loggedIn) {
            //             navigate("/");
            //         } else {
            //             setLoginError(true);
                    
            //         }
            //     }
            // });
    
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.container}>
                <Link to="/">
                    <img src={logo} width="40%"/>
                </Link>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="standard"
                        required
                        label="Email Address"
                        type="email"
                        fullWidth
                        className={classes.textField}
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <TextField
                        variant="standard"
                        required
                        label="Password"
                        type="password"
                        fullWidth
                        className={classes.textField}
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submitButton}
                    >
                        Sign In
                    </Button>
                    {loginError && 
                    <Alert severity="error">Invalid email or password</Alert>
                    }
                </form>
            </div>
        </Container>
    );
};

export default LoginPage;
