import React from 'react'
import { store } from '../productsStore/Store'
import "../styles/BestSellers.css";
import HoverImage from 'react-hover-image/build';
import { Link } from 'react-router-dom';

const BestSellers = ({onItemClick}) => {
  const randomBestSellers = store.filter(i => i.type === "ourBestSellers")
      .map(store => ({store, randomId: Math.random()}))
      .sort((a, b) => a.randomId - b.randomId)
      .map(v => ({...v.store})).slice(0,3);
  return (
    <div className='bestSellerMainParent flex flex-row'>
      {randomBestSellers.map((item) => {
        return (
            <Link to={`/${item.id}`} key={item.id} onClick={onItemClick}>
              <div key={item.id} className="bestSellerIndivitualItem">
                <HoverImage src={item.primaryImage} hoverSrc={item.hoverImg}   className="bestSellerImage rounded-xl mb-6"/>
                <p className='bestSellerName text-center mb-2'> {item.name} </p>
                <p className=' font-normal text-center'> ${item.price} </p>
              </div>
            </Link>
        )

      })}

    </div>
  )
}

export default BestSellers;
