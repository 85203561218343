export const categories = {
    sneakers: "Sneakers", loafers: "Loafers", running: "Running", basketBall: "Basketball", sandals: "Sandals"
}

export const store = [{
    id: 'SKU4728',
    name: `Men's Tree Runner GO`,
    price: 9,
    type: 'bestSeller',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/4y50FVO6KXe2h7HtQl2uOK/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/4y50FVO6KXe2h7HtQl2uOK/1',
    description: "Made to Go with the flow, our fan-fave sneaker keeps its signature breathable comfort while hitting the refresh button with a new elevated aesthetic and more springy support.\n",
    product_category: 'Shoes'
}, {
    id: 'SKU4187',
    name: 'Men\'s Tree Runner',
    price: 9,
    type: 'bestSeller',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3rhKORN3J95IP1bnAygejK/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3rhKORN3J95IP1bnAygejK/1',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU1496',
    name: 'Men\'s Tree Pipers',
    price: 9,
    type: 'bestSeller',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/Nbu3kjV8lDW43sqt5Emaw/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/Nbu3kjV8lDW43sqt5Emaw/1',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU6540',
    name: 'Men\'s Tree Loungers',
    price: 12,
    type: 'gift',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/5LF7SjmZ9EuzDkRjKWYv7l/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/5LF7SjmZ9EuzDkRjKWYv7l/1',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU7070',
    name: 'Men\'s Couriers',
    price: 10,
    type: 'gift',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3PZ7XhwdL28Idn8zw4y1ko/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3PZ7XhwdL28Idn8zw4y1ko/1',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU4080',
    name: 'Women\'s Tree Runners Go',
    price: 15,
    type: 'bestSeller',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3HavRXT9IoKJaPNylfFOj9/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3HavRXT9IoKJaPNylfFOj9/1',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU2099',
    name: 'Women\'s Wool Runner 2',
    price: 11,
    type: 'navbar-Shoes',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/5son7Lxs2ZvENQmUnFKmcf/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/5son7Lxs2ZvENQmUnFKmcf/2',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU3349',
    name: 'Women\s Tree Dasher',
    price: 18,
    type: 'navbar-BodyType',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/MagBBhPzUFDYvNOtT6Zkg/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/MagBBhPzUFDYvNOtT6Zkg/2',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU2247',
    name: 'Women\s Wool Pipers',
    price: 21,
    type: 'bestSeller',
    category: 'Sneakers',
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/73UsKoMMKAXH4xM3WLQp9h/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/73UsKoMMKAXH4xM3WLQp9h/1',
    description: "Our breathable, silky-smooth sneaker made with responsibly sourced eucalyptus tree fiber treads lightly in everything you do.",
    product_category: 'Shoes'
}, {
    id: 'SKU3422',
    name: 'Men\'s Tree Flyer',
    price: 56,
    type: 'navbar-TravelType',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/1WXQAmzp7p13DRNIHkv9rv/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/1WXQAmzp7p13DRNIHkv9rv/1',
    product_category: 'Shoes'
}, {
    id: 'SKU5296',
    name: 'Men\'s Tree Flyer 2',
    price: 28,
    type: 'navbar-TravelType',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3UefDm6GLKu8YUlvgS1nnN/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3UefDm6GLKu8YUlvgS1nnN/2',
    product_category: 'Shoes'
}, {
    id: 'SKU339',
    name: 'Men\'s Golf Dashers',
    price: 22,
    type: 'navbar-TravelType',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3H2j0fNsaEyId5m21BkIe3/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3H2j0fNsaEyId5m21BkIe3/1',
    product_category: 'Shoes'
}, {
    id: 'SKU3482',
    name: 'Men\'s Tree Dashers Relay',
    price: 44,
    type: 'navbar-TravelType',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/MagBBhPzUFDYvNOtT6Zkg/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/MagBBhPzUFDYvNOtT6Zkg/2',
    product_category: 'Shoes'
}, {
    id: 'SKU336',
    name: 'Men\'s Wool Dasher Mizzles',
    price: 52,
    type: 'ourBestSellers',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/66sxK7bB4sH65ckatTlrvp/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/66sxK7bB4sH65ckatTlrvp/2',
    product_category: 'Shoes'
}, {
    id: 'SKU8836',
    name: 'Women\'s SuperLight Trainers',
    price: 47,
    type: 'ourBestSellers',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/31q0MewfAdWjQNPFXN0X8U/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/31q0MewfAdWjQNPFXN0X8U/2',
    product_category: 'Shoes'
}, {
    id: 'SKU3751',
    name: 'Women\'s Trail Runners SWT',
    price: 21,
    type: 'navbar-NewType',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/7KJORc57q00EU8Bhk4zQue/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/7KJORc57q00EU8Bhk4zQue/1',
    product_category: 'Shoes'
}, {
    id: 'SKU7475',
    name: 'Women\'s Golf Dashers',
    price: 21,
    type: 'ourBestSellers',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/1sPdZY5u54qlSJsgUFGAwN/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/1sPdZY5u54qlSJsgUFGAwN/2',
    product_category: 'Shoes'
}, {
    id: 'SKU5605',
    name: 'Women\'s Tree Dasher',
    price: 61,
    type: 'ourBestSellers',
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/1WXQAmzp7p13DRNIHkv9rv/1',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/1WXQAmzp7p13DRNIHkv9rv/1',
    product_category: 'Shoes'
}, {
    id: 'SKU2333',
    name: 'Women\'s Tree Dasher 2',
    price: 45,
    category: 'Running',
    description: "This is the next evolution of our everyday active shoe with more responsive foam, extra grip, and an improved fit to keep you moving, and nature winning.",
    primaryImage: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3UefDm6GLKu8YUlvgS1nnN/2',
    hoverImg: 'https://cdn.allbirds.com/image/fetch/q_auto,f_auto/w_640,f_auto,q_auto/https://cdn.allbirds.com/image/upload/f_auto,q_auto/v1/production/colorway/en-US/images/3UefDm6GLKu8YUlvgS1nnN/2',
    product_category: 'Shoes'
}, {
    id: 'SKU1582',
    name: 'Air Jordan 1',
    price: 84,
    category: 'Basketball',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/5c3e36ac65db71da86b3b52f685e8200.jpg?v=1715796547',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/5c3e36ac65db71da86b3b52f685e8200.jpg?v=1715796547',
    description: "The Air Jordan 1 is one of the most important, and iconic, shoes of all time. Available in High, Low and Mid, there are Air Jordan 1 sneakers for whatever your needs are.",
    product_category: 'Shoes'
}, {
    id: 'SKU3917',
    name: 'Nike Book 1 RattleSnake',
    price: 25,
    category: 'Basketball',
    description: "Devin Booker is a craftsman who can lose a defender with an ankle-snatching stutter-go, then come back with a series of spellbinding jabs into a splashed jumper. Book’s signature shoe gives him the tools he needs to carve.",
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/74ddf199d850952516a773c7383e929d.jpg?v=1715015876',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/74ddf199d850952516a773c7383e929d.jpg?v=1715015876',
    product_category: 'Shoes'
}, {
    id: 'SKU3711',
    name: 'Air Jordan 5 Retro',
    price: 45,
    category: 'Basketball',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/6163b57dbb457509fb4bb98a51a42ad5.jpg?v=1712597816',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/6163b57dbb457509fb4bb98a51a42ad5.jpg?v=1712597816',
    product_category: 'Shoes',
    description: "There's a reason MJ's 5th shoe remains a fan favorite. Its fierce zig-zag midsole details, iconic lace locks and mesh-inspired shoe cage are instantly recognizable, giving you stand-out style you can rock any day of the week.",
}, {
    id: 'SKU1562',
    name: 'Jordan Spizike Low',
    type: 'ourBestSellers',
    price: 157,
    category: 'Basketball',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/40fb76e5dc33e47037dc07ccb7734a7d.jpg?v=1714171618',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/40fb76e5dc33e47037dc07ccb7734a7d.jpg?v=1714171618',
    description: "The Spizike takes elements of five classic Jordans, combines them, and gives you one iconic sneaker. It's an homage to Spike Lee formally introducing Hollywood and hoops in a culture moment.",
    product_category: 'Shoes'
}, {
    id: 'SKU2807',
    name: 'Jordan Zion 3',
    price: 24,
    category: 'Basketball',
    description: "Every challenge Zion faces—intense training, the team at the other end of the court, or even gravity itself—you know he's gonna rise above. This version of the Zion 3, with its dreamy gradient fade, channels the energy that comes with every new day. ",
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/be9ba45181f989ebbb2a9d452896b296.jpg?v=1715273579',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/be9ba45181f989ebbb2a9d452896b296.jpg?v=1715273579',
    product_category: 'Shoes'
}, {
    id: 'SKU4934',
    name: 'Crocs Star Wars Grogu Classic',
    price: 75,
    category: 'Sandals',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/77cd6ece0d0e81eaf30bb868645a27f4_ac91656d-dcad-41c9-b6cc-4422db83248e.jpg?v=1713302710',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/77cd6ece0d0e81eaf30bb868645a27f4_ac91656d-dcad-41c9-b6cc-4422db83248e.jpg?v=1713302710',
    description: "Experience out-of-this-world comfort with the Classic Crocs adorned with the lovable Grogu from Star Wars! These clogs bring a touch of galactic charm to your everyday style, whether you're exploring the universe or relaxing at home.",
    product_category: 'Shoes'
}, {
    id: 'SKU401',
    name: 'Birkenstock Boston Mens Sandals',
    price: 78,
    category: 'Sandals',
    type: 'ourBestSellers',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/products/cc6f42831425fde139141f3234102b19.jpg?v=1710439570',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/products/cc6f42831425fde139141f3234102b19.jpg?v=1710439570',
    description: "So classic and consistently comfortable, the Boston clog has become a coveted, year-round favorite. Soft suede lends an effortless feel to the style. Complete with an original BIRKENSTOCK footbed that shapes to you for the ultimate in all-day support.",
    product_category: 'Shoes'
}, {
    id: 'SKU9450',
    name: 'New Balance 200 Slide',
    price: 57,
    category: 'Sandals',
    type: 'ourBestSellers',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/beff61bf9c67b694a3a6400d3d321f94.jpg?v=1713226944',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/beff61bf9c67b694a3a6400d3d321f94.jpg?v=1713226944',
    description: "Keep your comfort levels at a maximum in the locker room and beyond with our 200 sport slides. The springy foam footbed contours to your foot to deliver comfort and cushioning, making these sandals a top choice after your workout or when relaxing on days off",
    product_category: 'Shoes'
}, {
    id: 'SKU4925',
    name: 'Adidas Yeezy Slide Slate',
    price: 88,
    category: 'Sandals',
    type: 'ourBestSellers',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/products/7380f821f51c92abd65989f28db5178c.jpg?v=1694473192',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/products/7380f821f51c92abd65989f28db5178c.jpg?v=1694473192',
    description: "The YEEZY SLIDE features injected EVA foam to provide lightweight durability, the soft top layer in the footbed offers immediate step in comfort. The outsole utilizes strategic groove placement for optimal comfort and traction.",
    product_category: 'Shoes'
}, {
    id: 'SKU7646',
    name: 'Nike Calm Slide',
    price: 18,
    type: 'bodyCare all',
    category: 'Sandals',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/products/8584bf526c1d472f0876cbd12fa42fe7.jpg?v=1708979578',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/products/8584bf526c1d472f0876cbd12fa42fe7.jpg?v=1708979578',
    stars: 4,
    description: 'Enjoy a calm, comfortable experience—no matter where your day off takes you. Made with soft yet responsive foam, these lightweight slides are easy to style and easy to pack. While the water-friendly design makes them ideal for the beach or pool, the minimalist look is elevated enough to wear around in the city. Time to slide in and check out.',
    product_category: 'Shoes'
}, {
    id: 'SKU9397',
    name: 'Jordan Jumpman Slides',
    price: 15,
    type: 'bodywash all',
    category: 'Sandals',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/products/b39aa6fcf286409fa7f8f9f2f2208db8.jpg?v=1707156055',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/products/b39aa6fcf286409fa7f8f9f2f2208db8.jpg?v=1707156055',
    stars: 5,
    description: 'Slide in and go. Enjoy the cushioning of thick, lightweight foam for beach days or post-game hangouts.',
    product_category: 'Shoes'
}, {
    id: 'SKU7231',
    name: 'Nike Offcut Mens Slides',
    price: 15,
    type: 'bodywash all',
    category: 'Sandals',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/files/f2964155106785911335e0865a6bedad.jpg?v=1712683698',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/files/f2964155106785911335e0865a6bedad.jpg?v=1712683698',
    stars: 5,
    description: 'These slides are designed to help you relax and recharge. Innovative dual-layered foam underfoot pairs with a plush strap to bring the comfort, while the iconic AF1 pivot circle pattern on the outsole and block letter branding casually nod to heritage hoops. What are you waiting for, get lounging.',
    product_category: 'Shoes'
}, {
    id: 'SKU6252',
    name: 'Nike Calm Slides',
    price: 11,
    type: 'bodywash all',
    category: 'Sandals',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/products/e6e06a7dfeabfefded2130083b1f4d84.jpg?v=1710260606',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/products/e6e06a7dfeabfefded2130083b1f4d84.jpg?v=1710260606',
    stars: 5,
    description: 'Enjoy a calm, comfortable experience—no matter where your day off takes you. Made with soft yet responsive foam, these lightweight slides are easy to style and easy to pack. While the water-friendly design makes them ideal for the beach or pool',
    product_category: 'Cosmetics_body_care'
}, {
    id: 'SKU1923',
    name: 'Crocs Classic',
    price: 15,
    type: 'bodyLotion all',
    category: 'Sandals',
    primaryImage: 'https://cdn.shopify.com/s/files/1/0852/3376/products/7f716b682b3de14043059039315a64a8.jpg?v=1706731105',
    hoverImg: 'https://cdn.shopify.com/s/files/1/0852/3376/products/7f716b682b3de14043059039315a64a8.jpg?v=1706731105',
    stars: 5,
    description: 'Join the comfort revolution! Pull-on a pair of the bright and ultra-comfortable Crocs Classic clogs to give your feet a treat. With an array of colors to choose from, there’s a pair for every occasion',
    product_category: 'Shoes'
}, {
    id: 'SKU700',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313962865_130486163124825_3872437440492064747_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=-lmG2O4dOucAX8QcJ5M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAHT7pOcFYxRAnf56OnXFtLSyR7WayrzECFZkdsW9r_Rw&oe=63731B07'
}, {
    id: 'SKU1808',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314474189_3459125960988849_5301653039305226582_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=Py6h3bGoC_MAX-Iews1&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCRfHYUT_SCZWQYEKljTugVmBowTNDFYavEXU44ET63yg&oe=6372D043'
}, {
    id: 'SKU109',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314478179_427847786212552_2166145489506263286_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=2EMtwONXdlgAX-YTXAC&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCNZD2XX5ypy58-6eUsbaHb2adjwe2LT6_UhoyCDB-JEQ&oe=637318F2'
}, {
    id: 'SKU383',
    type: 'iGPosts',
    primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3)'
}, {
    id: 'SKU9667',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313904838_644828754033309_8190401506066273847_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=5vGwQp3agyYAX9GzdN9&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCpWTRt3wjB65-i_cMABs2uurxkJxlaDO-j0ZAPoeR3lw&oe=63723C04'
}, {
    id: 'SKU1471',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313842695_842398890281843_8923876477054992822_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=FKdEGPiWctQAX9PD1Tt&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD5A7KTnVB45p6M9Xl9zQSm15B1vnoU6JASPBgW62G_JA&oe=6373D0C0'
}, {
    id: 'SKU8139',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313360482_421805233489433_2180398075982936387_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=TC_YP3KsBKgAX_UWuiL&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDL0rgDBFQc5kpLJiasFCbpam_yHNOF1Eqr6tkjJyjW0w&oe=6372D9EE'
}, {
    id: 'SKU9153',
    type: 'iGPosts',
    primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/312923519_415357727475132_3000659168966820929_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MSPAwUxDLT0AX_q9fA5&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDkzxVRC1ejkowXInjU7aaYjuS6iDBJV372Q0p9R1Amkg&oe=63738D15](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/312923519_415357727475132_3000659168966820929_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=MSPAwUxDLT0AX_q9fA5&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDkzxVRC1ejkowXInjU7aaYjuS6iDBJV372Q0p9R1Amkg&oe=63738D15)'
}, {
    id: 'SKU7465',
    type: 'iGPosts',
    primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314538850_842391733849053_5837409325218948914_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=yl2x9QL1CI0AX_3K42M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDtUpG3NMqPdhdjhPSsqtXIWhp63oIPCOXxHQUmp1Ro8A&oe=6373247D](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/314538850_842391733849053_5837409325218948914_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=yl2x9QL1CI0AX_3K42M&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfDtUpG3NMqPdhdjhPSsqtXIWhp63oIPCOXxHQUmp1Ro8A&oe=6373247D)'
}, {
    id: 'SKU3033',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313918031_164815222899324_5118105442635469994_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=75C5M_xl7W4AX9aXcJZ&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBPLXtxavxi0qLvoBLwTHOcTh_WqHuC3dhATNZ7FhFVyw&oe=6372D076'
}, {
    id: 'SKU8487',
    type: 'iGPosts',
    primaryImage: '[https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3](https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313518379_188429183711300_8151278366243215086_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=HQxZfX3zuaQAX_zfcBH&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfC-0dxgjeZAMW4j5Und2ZipxnBPJgAAw0eIHPY1NTH3vA&oe=6373ECA3)'
}, {
    id: 'SKU3137',
    type: 'iGPosts',
    primaryImage: 'https://scontent-lcy1-1.cdninstagram.com/v/t51.29350-15/313859159_1178762139722510_2208267463138928303_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=fDD-DllQ_fgAX_gepIB&_nc_ht=scontent-lcy1-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA22pcsJqnauAqOBE2egFF1VhPe-wEPMDIQMOxhw2zCMw&oe=63741165'
}, {
    id: 'SKU898',
    name: 'The Ravello Loafer',
    price: 180,
    type: 'loafer',
    category: 'Loafers',
    stars: 5,
    descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
    primaryImage: 'https://rothys.com/cdn/shop/files/123_RavelloLoafer_StoneBlack_OnFigure_0730_650x.jpg?v=1699033803',
    hoverImg: 'https://rothys.com/cdn/shop/files/123_RavelloLoafer_StoneBlack_OnFigure_0730_650x.jpg?v=1699033803',
    product_category: 'Shoes'
}, {
    id: 'SKU8353',
    name: 'The Sand Loafer',
    price: 200,
    type: 'ourBestSellers',
    category: 'Loafers',
    stars: 5,
    descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
    primaryImage: 'https://images.unsplash.com/photo-1615979474401-8a6a344de5bd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bG9hZmVyfGVufDB8fDB8fHwy',
    hoverImg: 'https://images.unsplash.com/photo-1615979474401-8a6a344de5bd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bG9hZmVyfGVufDB8fDB8fHwy',
    product_category: 'Shoes'
}, {
    id: 'SKU5574',
    name: 'The Navy Loafer',
    price: 180,
    type: 'loafer',
    category: 'Loafers',
    stars: 5,
    descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
    primaryImage: 'https://images.unsplash.com/photo-1616406432452-07bc5938759d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bG9hZmVyfGVufDB8fDB8fHwy',
    hoverImg: 'https://images.unsplash.com/photo-1616406432452-07bc5938759d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bG9hZmVyfGVufDB8fDB8fHwy',
    product_category: 'Shoes'
}, {
    id: 'SKU8971',
    name: 'The Boot Sneakers',
    price: 201,
    type: 'loafer',
    category: 'Sneakers',
    stars: 5,
    descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
    primaryImage: 'https://rothys.com/cdn/shop/products/056_Hudson_pdp_B_650x.jpg?v=1628807453',
    hoverImg: 'https://rothys.com/cdn/shop/products/056_Hudson_pdp_B_650x.jpg?v=1628807453',
    product_category: 'Shoes'
}, {
    id: 'SKU6832',
    name: 'The Arctic Sneakers',
    price: 201,
    type: 'shoe',
    category: 'Sneakers',
    stars: 5,
    descr: 'Inspired by coastal getaways, this sophisticated loafer in black features a sleek design, ultra-cushy insoles and durable outsoles. Comfort you can wear all day—from morning espresso to evening Aperol spritz.',
    primaryImage: 'https://rothys.com/cdn/shop/files/056_RS01_ArcticStone_Side_650x.jpg?v=1706919590',
    hoverImg: 'https://rothys.com/cdn/shop/files/056_RS01_ArcticStone_Side_650x.jpg?v=1706919590',
    product_category: 'Shoes'
}]