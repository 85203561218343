import React from 'react'
import cartgif from "../assets/cartGif.png";
import "../styles/BestSellers.css";


const EmptyCart = () => {
    return (
        <div className='emptyCartMainParent text-center relative top-20 h-full'>
            <img src={cartgif} className="absolute cg" style={{ maxWidth: '22%', maxHeight: '22%', width: 'auto', height: 'auto' }} />
        </div>
    )
}

export default EmptyCart