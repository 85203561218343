import {Link, useParams} from "react-router-dom";
import {categories, store} from "../productsStore/Store";
import {Box, Typography} from "@material-ui/core";
import HoverImage from "react-hover-image/build";
import "../styles/Category.css";
import React from "react";
import OurBestSellers from "./OurBestSellers";

export const Category = () => {
    const {id = "cleanser"} = useParams();

    const category = categories[id];

    return (
        <>
            <p className="category-title">{String(category).toUpperCase()}</p>
            <div className='category-wrapper'>
                {store.filter(item => item.category === category).map((item) => (
                    <OurBestSellers
                        key={item.id}
                        id={item.id}
                        title={item.name}
                        price={item.price}
                        image={item.primaryImage}
                        product_category={item.product_category}
                    />
                ))}

            </div>
        </>
    )
}