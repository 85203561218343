import React, {useContext, useEffect} from 'react';
import "../styles/Navbar.css";
import logo from "../assets/cara.png";
import {useState} from 'react';
import BestSellers from './BestSellers';
import {categories} from '../productsStore/Store';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {Link} from 'react-router-dom';
import {AuthContext} from '../App';
import {useNavigate} from 'react-router-dom';
import {Avatar, Badge, Box, Button, Menu, MenuItem} from '@material-ui/core';
import {deepOrange} from '@material-ui/core/colors';

const NavBar = () => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const navigate = useNavigate();
    const handleClose = () => {
        setAnchorEl(null);
        //   navigate('/orders');
    };
    const [showDropdown, setShowDropdown] = useState(false);
    const handleDropdown = () => {
        setShowDropdown(!showDropdown);
    }

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const {logout} = useContext(AuthContext);
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    // const cartItems = [];//useSelector((state) => state.user[currentUser.id].cartItems);


    // let totalQuantity = 0;
    // cartItems.forEach(item => {
    //     totalQuantity += item.quantity;
    // });

    const {cartLen, populateCartLen} = useContext(AuthContext);
    useEffect(() => {
        populateCartLen(currentUser.id);
    }, []);

    const showHandler = () => {
        setShow(true);
        setShow2(false);
        setShow3(false);
        setShow4(false);
    };

    const showHandler2 = () => {
        setShow2(true);
        setShow(false);
        setShow3(false);
        setShow4(false);
    };

    const showHandler3 = () => {
        setShow3(true);
        setShow(false);
        setShow2(false);
        setShow4(false);
    };

    const showHandler4 = () => {
        setShow4(true);
        setShow(false);
        setShow2(false);
        setShow3(false);
    };

    const dontShowHandler = () => {
        setShow(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
    };

    const handleLogout = () => {
        console.log(currentUser);
        logout();
        navigate('/login');
    }


    const stringToColor = (string) => {
        let hash = 0;
        let i;
        console.log(string);
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';
        console.log(hash);

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        console.log(color);

        return color;
    }

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: deepOrange[500],
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <div>
            <header className="banner" role="banner">
                <nav className="navbar shadow" role="navigation" aria-label="menu">
                    <Link className="logo-link" to="/">
                        <img src={logo} className="logo" alt="Logo"/>
                    </Link>
                    {/* <div className='navbar-elements'> */}
                    <ul style={{height: "100%", marginTop: "1.3rem"}}>
                        <li
                            className={`dropdown nav-link nav-link-fade-up transition-all duration-700 ${show ? 'highlight' : ''} text-center`}
                            onMouseOver={showHandler}>
                            Top Products
                            {show && (
                                <div>
                                    <ul className="dropdown-nav" onMouseLeave={dontShowHandler}>
                                        <BestSellers onItemClick={dontShowHandler}/>
                                    </ul>
                                </div>
                            )}
                        </li>
                        {Object.keys(categories).map(category => (
                            <li className="nav-link nav-link-underline" key={category}>
                                <Link to={"/category/" + category}>
                                {categories[category]}
                                </Link>
                            </li>
                        ))}
                    </ul>
                <Box component={"ul"} className="nav-list-right-wrapper mr-2">
                    <li>
                        <Link to="/cart">
                            <Badge badgeContent={cartLen} color="secondary">
                                <ShoppingCartIcon className='text-2xl text-right'/>
                            </Badge>
                        </Link>
                    </li>
                    <li>
                        {currentUser ? (
                            <div className='user-profile'>
                                <div onClick={handleDropdown}>
                                        <Button
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <Avatar className='avatar' {...stringAvatar(currentUser.name)} />
                                        </Button>
                                        <Menu
                                            className='profile-btn'
                                            style={{flexDirection: "column"}}
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem className='cart-total-header'>
                                                <b>{currentUser.name}</b>
                                            </MenuItem>
                                            <MenuItem onClick={handleClose}>
                                                <Link to='/orders'>
                                                    Orders
                                                </Link>
                                            </MenuItem>
                                            <MenuItem onClick={handleLogout}>
                                                Logout
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            ) : (
                                <Link to="/login">Login</Link>
                            )}

                        </li>
                    </Box>
                    {/* </div> */}


                </nav>
            </header>
        </div>
    );
};

export default NavBar;
