import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { cartActions } from '../redux-state/CartState';
import { Link } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { Card, CardContent, CardMedia, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../App';

const useStyles = makeStyles((theme) => ({
    card: {
        width: 300,
        height: '100%', // Set the height to 100% to make all cards the same height
        margin: theme.spacing(2),
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: theme.spacing(2),
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.01)',
        },
    },
    media: {
        height: 300,
        objectFit: 'cover',
    },
    cardContent: {
        display: "flex",
        flexDirection: "column"
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        marginBottom: theme.spacing(1),
        whiteSpace: 'nowrap', // Truncate the title if it goes to the next line
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    price: {
        fontSize: '1.5rem',
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
        alignSelf: 'center',
        width: "100%"
    },
}));

const OurBestSellers = (props) => {
    const classes = useStyles();

    const { title, price, id, image, product_category } = props;
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    const dispatch = useDispatch();
    const toast = useToast();
    const { populateCartLen, setCartLen, cartLen, setCartItems } = useContext(AuthContext);
    const addItemToCartHandler = (e) => {
        dispatch(
            cartActions.addItemToCart({
                id,
                price,
                title,
                image,
                product_category
            })
        );
        const addItem = async () => {
            var myHeaders = new Headers();
            myHeaders.append("userId", currentUser.id);
            myHeaders.append("Content-Type", "application/json");
            console.log(product_category)
            var raw = JSON.stringify({
                "userId": null,
                "itemId": id,
                "name": title,
                "description": "The number one facial cream out there.",
                "price": price,
                "quantity": 1,
                "category": product_category,
                "imageTag": image
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
            

            const result = await fetch("http://104.196.11.202:8080/v1/item", requestOptions);
            const resultJson = await result.json();
            let total = 0;
            if (resultJson.length > 0) {
                resultJson.map((item) => {
                    total += item.quantity;
                });
                setCartLen(total);
                setCartItems(resultJson);
                // console.log(cartLen)
                console.log("Item added to cart");
            }
        };
        addItem();
        toast({
            title: "Item added to cart",
            description: `${title} has been added to your cart.`,
            status: "success",
            duration: 1000,
            isClosable: true,
            variant: "subtle",
            position: "top-right",
            containerStyle: {
                color: "black",
            }
        });
    };

    console.log(classes);

    return (
        <Card className={classes.card}>
            <Link to={`/${id}`}>
                <CardMedia className={classes.media} image={image} alt="Shoes" />
            </Link>
            <CardContent className={classes.cardContent}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.price}>${price}</Typography>
                <Button
                    className={classes.button + ' spAddTOCart'}
                    variant="contained"
                    color="primary"
                    onClick={addItemToCartHandler}
                >
                    Buy Now
                </Button>
            </CardContent>
        </Card>
    );
};

export default OurBestSellers;
