import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, Card, CardContent, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {AuthContext} from '../App';
import {merchantId} from "../constants/merchantId";

const CartTotal = () => {
    const [cartID, setCartID] = React.useState("");
    const [orderID, setOrderID] = React.useState("");
    const [policies, setPolicies] = React.useState([]);
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    const [returnPolicy, setReturnPolicy] = useState("");
    const [isAnalyzeLoading, setIsAnalyzeLoading] = useState(false);
    // const { cartItems, totalPr } = props.cartDetails;
    const {cartLen, cartItems} = useContext(AuthContext);//cartItems.length;
    const [checkoutLoader, setCheckoutLoader] = useState(false);
    const [policyData, setPolicyData] = useState(null);
    const dispatch = useDispatch();
    let hostUrl = 'https://api.pinch.ai';
    const [totalPr, setTotalPr] = useState(0);

    // const totalPrice = cartItems.map(item => {
    //     return item.quantity * item.price;
    // }).reduce((totalPrice, singleItemPrice) => totalPrice + singleItemPrice, 0);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const {setCartLen, setCartItems} = useContext(AuthContext);


    let items = [];

    const skuIds = [];
    useEffect(() => {
        items = [];
        let tempTotalPr = 0;
        console.log(cartItems);
        if(cartItems.length > 0) {
            for (let i = 0; i < cartItems.length; i++) {
                skuIds.push(cartItems[i].itemId);
                items.push({
                    "product": {
                        "identifierType": "UPC",
                        "identifier": cartItems[i].itemId,
                        "category": cartItems[i].category ?? "Fashion"
                    },
                    "count": cartItems[i].quantity,
                    "perItemPrice": {
                        "currencyCode": "840",
                        "amount": cartItems[i].price
                    }
                });
                tempTotalPr += cartItems[i].price * cartItems[i].quantity;
                console.log("totalPr: " + totalPr);
            }
            setTotalPr(tempTotalPr);
            console.log('in fetch inside')
            const fetchData = async () => {
                const myHeaders = new Headers();
                myHeaders.append("Accept", "*/*");
                myHeaders.append("x-merchant-id", merchantId);
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Access-Control-Allow-Origin", "*");

                const raw = JSON.stringify({
                    "merchantCartID": "ABB10903-" + new Date().getTime(),
                    "previousCartID": "ABB10903-1234",
                    "totalCartCost": {
                        "currencyCode": "840",
                        "amount": tempTotalPr
                    },
                    "cart": items,
                    "customer": {
                        "name": "Test User",
                        "deviceFingerprints": localStorage.getItem("visitorId") ? [
                            {
                                algorithm: "beacon",
                                fingerPrint: localStorage.getItem("visitorId").split("#")[1] ,
                            }
                        ] : [],
                        "contactInformation": [
                            {
                                "contactType": "ContactAddress",
                                "address": {
                                    "line1": "9241",
                                    "line2": "Scott City Monument",
                                    "line3": null,
                                    "line4": null,
                                    "city": "Kansas",
                                    "state": "KS",
                                    "postalCode": "67871",
                                    "country": "US",
                                    "geoCode": {
                                        "longitude": "-100.908056",
                                        "latitude": "38.478535"
                                    }
                                },
                                "addressType": "Billing"
                            },
                            {
                                "contactType": "ContactAddress",
                                "address": {
                                    "line1": "3706",
                                    "line2": "Parkshire Dr XYZ",
                                    "line3": null,
                                    "line4": null,
                                    "city": "Pearland",
                                    "state": "TX",
                                    "postalCode": "17214",
                                    "country": "US",
                                    "geoCode": {
                                        "longitude": "-95.498518",
                                        "latitude": "29.467289"
                                    }
                                },
                                "addressType": "Mailing"
                            },
                            {
                                "contactType": "EMail",
                                "address": currentUser.email
                            },
                            {
                                "contactType": "Phone",
                                "phone": 9922112233
                            }
                        ]
                    },
                    "merchant": {
                        "brand": "Allbirds",
                        "locationID": "OUTLET-123"
                    },
                    "customData": {
                        "product_sku": skuIds.join("::")
                    }
                });

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };


                try {
                    const response = await fetch(hostUrl + "/cart/analyze", requestOptions);
                    console.log("response: " + response.status + " ");
                    if (!response.ok) {
                        throw new Error('Error fetching policy');
                    }

                    const result = await response.json();
                    setPolicyData(result);
                    let cartPolicies = [];
                    for (let i = 0; i < result.cartPolicies.length; i++) {

                        cartPolicies.push(result.cartPolicies[i].policyID);
                    }
                    setCartID(result.pinchCartID);
                    setPolicies(cartPolicies);


                    // Update later
                    // const response = await fetch('https://api.example.com/policies');
                    // const data = await response.json();
                    // setPolicyData(data);
                    console.log("CartTotal.js: Fetching policy data");
                } catch (error) {
                    console.error('Error fetching policy data:', error);
                }
            };

            fetchData();
        }
    }, [cartItems]);

    const handleCheckout = () => {
        console.log("CartID: " + cartID);
        setCheckoutLoader(true);
        const sendPurchase = async () => {
            try {

                var purchaseHeaders = new Headers();
                purchaseHeaders.append("Accept", "*/*");
                purchaseHeaders.append("x-merchant-id", "13001");
                purchaseHeaders.append("Content-Type", "application/json");
                purchaseHeaders.append("Access-Control-Allow-Origin", "*");

                var raw = JSON.stringify({
                    "cartID": cartID, //"3-02293-203-203=2--+99" + new Date().getTime(),
                    "merchantCartID": "ABB10903-1234",
                    "orderStatus": "Pending",
                    "purchasedPolicies": policies,
                    "paymentInfo": [
                        {
                            "amount": {
                                "amount": totalPr
                            },
                            "instrument": "CC-M",
                            "CVV": "234",
                            "postalCode": "500081",
                            "AID": "A000000003101001"
                        }
                    ]
                });

                var requestOptions = {
                    method: 'POST',
                    headers: purchaseHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                // Purchase call
                const response = await fetch("https://api.pinch.ai/cart/purchase", requestOptions)
                console.log("response: " + response.status + " " + response.statusText);
                if (!response.ok) {
                    throw new Error('Error completing purchase');
                }

                const result = await response.json();
                console.log("Result for purchase:" + result.orderID);
                setOrderID(result.orderID);
                sendDataToOrderList(result.orderID);
            } catch (error) {
                console.log("error: " + error);
            }
        };

        sendPurchase();
    };


    const sendDataToOrderList = (responseOrderId) => {
        console.log("orderId: " + responseOrderId);
        // const response = fetch("localhost:8080/v1/order/", requestOptions) 
        // dispatch(orderActions.addOrder({ id: responseOrderId, product: cartItems.map(item => item.name).join(', '), price: totalPr }));
        console.log("Order added at CartTotal.js");

        // Reset cart state and clear local storage
        // cartItems.forEach(item => {
        //     console.log(item);
        //     dispatch(cartActions.removeItemFromCart(item.id));
        // });

        const clearCartItems = async () => {
            setTimeout(() => {
                setCartLen(0);
                setCartItems([]);
            }, 1000);

            var myHeaders = new Headers();
            myHeaders.append("userId", currentUser.id);
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            const response = await fetch("http://104.196.11.202:8080/v1/item/clear", requestOptions);
            const result = await response.text();
            console.log("Cart cleared: " + result);
            console.log("response: " + response.status + " " + response.statusText);
        };
        clearCartItems();


        const sendToOrderList = async () => {
            try {
                var orderHeaders = new Headers();
                orderHeaders.append("userId", currentUser.id);
                orderHeaders.append("Content-Type", "application/json");
                console.log("responseOrderId: " + responseOrderId);
                console.log("currentUser.id: " + currentUser.id);

                var raw = JSON.stringify({
                    "userId": null,
                    "orderId": responseOrderId,
                    "price": totalPr,
                    "status": "Delivered",
                    "returnId": null,
                    "product": cartItems.map(item => item.itemId).join(','),
                    "productCategory": cartItems.map(item => item.category).join(','),
                    "policyId": policyData.cartPolicies[0].policyID,
                    "policyName": policyData.cartPolicies[0].policyName,
                    timestamp: new Date().getTime()
                });

                var requestOptions = {
                    method: 'POST',
                    headers: orderHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                const result = await fetch("http://104.196.11.202:8080/v1/order", requestOptions);
                console.log("response aaya: " + result.status + " " + result.statusText);
            } catch (error) {
                console.log("error: " + error);
            }
        };

        sendToOrderList();

    }

    const handleClickOpen = () => {
        setCheckoutLoader(true);
        handleCheckout();
        // setOpen(true);
    };

    const card = (
        <React.Fragment>
            <CardContent>
                <Typography variant="h6" className="cart-total-header" sx={{fontSize: 14}} color="text.secondary"
                            gutterBottom>
                    <b>Billing details</b>
                </Typography>
                <div>
                    <div className='price-item'>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            <b>Total</b>
                        </Typography>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            ${totalPr}
                        </Typography>
                    </div>
                    <div className='price-item'>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            <b>Shipping</b>
                        </Typography>
                        <div className='price-item no-border'>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                {totalPr < 100 ? <p> $15 </p> : <p>
                                    <del>$15</del>
                                </p>}
                            </Typography>
                            {
                                totalPr >= 100 ?
                                    <p className='free-shipping'><b>FREE</b></p>
                                    : null
                            }
                        </div>

                    </div>
                    <div className='price-item'>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            <b>Platform fee</b>
                        </Typography>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            $5
                        </Typography>
                    </div>
                    <div className='price-item'>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            <b>Tax</b>
                        </Typography>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            ${((totalPr * 5) * 1.0) / 100}
                        </Typography>
                    </div>
                    <div className='price-item total-summary'>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            <b>Total amount</b>
                        </Typography>
                        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                            ${Math.round(totalPr + (totalPr < 100 ? 20 : 0) + 5 + ((totalPr * 5) * 1.0) / 100)}
                        </Typography>
                    </div>
                    <br/>
                    <br/>
                    <div>
                        <h3><b>Return Policy</b></h3>
                        *<em>
                        Free returns are available within 30 days of purchase.
                        Customers have the option to exchange their product or receive a refund to the original payment
                        method. This offer is valid within 14 days of us receiving the return.
                    </em>

                        <p>
                            <br/>
                            <em>
                                We monitor return activity for abuse and reserve the right to limit returns or exchanges
                                in
                                all instances.
                            </em>
                        </p>

                        <br/>
                        <br/>
                        {checkoutLoader ?
                            <Alert severity="success">
                                <span>Order placed successfully</span>
                            </Alert>
                            :
                            <button onClick={handleClickOpen} className='spAddTOCart checkoutBtn'>
                                <div className=' text-center'>
                                    Place order
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </CardContent>
        </React.Fragment>
    );


    return (

        <div>
            <Box className="price-details" sx={{display: 'flex'}}>
                <Card variant="outlined">{card}</Card>
            </Box>

        </div>
    );
};

export default CartTotal;
