import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Cart from './Cart';
import CartPageFaq from './CartPageFaq';
import CartTotal from './CartTotal';
import EmptyCart from './EmptyCart';
import YouMayAlsoLike from './YouMayAlsoLike';
import "../styles/BestSellers.css";
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import { AuthContext } from '../App';

const CartHold = () => {

    const [cartItems, setCartItems] = React.useState([]);
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    const [loader, setLoader] = React.useState(true);

    const fetchCartItems = async () => {
        var myHeaders = new Headers();
        myHeaders.append("userId", currentUser.id);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        const result = await fetch("http://104.196.11.202:8080/v1/item", requestOptions);
        const resultJson = await result.json();
        setCartItems(resultJson);
        console.log(resultJson);
        setLoader(false);
    };

    
    // const cartItems = useSelector((state) => state.cart.items);
    const { cartLen } = useContext(AuthContext);

    const totalPrice = cartItems.map(item => {
        return item.quantity * item.price;
    }).reduce((totalPrice, singleItemPrice) => totalPrice + singleItemPrice, 0);
    useEffect(() => {
        window.scrollTo(0, 0);

        fetchCartItems();
    }, [cartLen]);

    const card = (
        <React.Fragment>
            <CardContent className='card-content'>
                <div className='address-card'>
                    <div>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            <b>Deliver to: </b> {currentUser.name}
                        </Typography>
                        <Typography sx={{ fontSize: 8 }} color="text.secondary" gutterBottom>
                            {currentUser.addressLine1 + "," + currentUser.addressLine2 + "," + currentUser.city + "," + currentUser.postalCode + "," + currentUser.state + "," + currentUser.country} 
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </React.Fragment>
    );


    return (
        <div>
            {loader ? 
            <Box sx={{ display: 'flex', width: '100%', alignContent:'center', alignItems: 'center', height: '100%' }} className='emptyCartMainParent text-center relative top-20 h-full'>
                <CircularProgress size={100} />
            </Box>
            : 
            <div>
            {cartLen === 0 ? <EmptyCart /> : <>
            <Box sx={{ width: '100%'}}>
                    <Grid container className='cart-grid'>
                        <Grid item xs={8} className='cart-page-grid-item' >
                            <div>
                                <div className='cartList'>
                                <Card variant="outlined">{card}</Card>
                                    <Typography variant="h5" className='cart-header'><b>List of items in your bag</b></Typography>
                                    {cartItems.map((item) => (
                                        <div>
                                            <Cart
                                                key={item.id}
                                                item={{
                                                    id: item.id,
                                                    itemId: item.itemId,
                                                    title: item.name,
                                                    quantity: item.quantity,
                                                    total: item.totalPrice,
                                                    price: item.price,
                                                    description: item.description,
                                                    image: item.imageTag,
                                                    cartLength: cartItems.length,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} className='cart-total-div'>
                        <CartTotal />
                            {/* cartDetails={{cartItems: cartItems, totalPr: totalPrice}} /> */}
                        </Grid>
                    </Grid>

                    <Typography variant='h5' className=' similar-product relative'>YOU MAY ALSO LIKE</Typography>
                    <YouMayAlsoLike />
                    <CartPageFaq />
                </Box>

            </>
            }
        </div>
            }
        </div>
    )
}

export default CartHold;