import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        totalQuantity: 0,
    },
    reducers: {
        addItemToCart(state, action) {
            const newItem = action.payload;
            const existingItem = state.items.find((item) => item.id === newItem.id);
            state.totalQuantity++;
            console.log("Item added to cart by reducers");

            if (!existingItem) {
                state.items.push({
                    id: newItem.id,
                    price: newItem.price,
                    quantity: 1,
                    totalPrice: newItem.price,
                    name: newItem.title,
                    picture: newItem.image,
                    product_category: newItem.product_category
                });
            } else {
                existingItem.quantity++;
                existingItem.totalPrice = existingItem.totalPrice + newItem.price;
            }
        },

        removeItemFromCart(state, action) {
            const id = action.payload;
            const existingItem = state.items.find((item) => item.id === id);
            state.totalQuantity--;

            if (existingItem.quantity === 1) {
                state.items = state.items.filter((item) => item.id !== id);
            } else {
                existingItem.quantity--;
                existingItem.totalPrice = existingItem.totalPrice - existingItem.price;
            }
        },
    },
});

// Load state from local storage
// const persistedState = localStorage.getItem("cartState")
//     ? JSON.parse(localStorage.getItem("cartState"))
//     : {};

// const store = configureStore({
//     reducer: { cart: cartSlice.reducer },
//     preloadedState: persistedState,
// });

// // Save state to local storage whenever the state changes
// store.subscribe(() => {
//     localStorage.setItem("cartState", JSON.stringify(store.getState()));
// });

// export default store;
export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
