import React, {useMemo} from 'react';
import { store } from "../productsStore/Store";
import OurBestSellers from './OurBestSellers';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    ourBestSellersMainParent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Products = () => {
    const classes = useStyles();
    const randomBestSellers = useMemo(() => store.filter(i => i.type === "ourBestSellers")
        .map(store => ({store, randomId: Math.random()}))
        .sort((a, b) => a.randomId - b.randomId)
        .map(v => ({...v.store})), []);
    return (
        <div className={classes.ourBestSellersMainParent}>
            {randomBestSellers.map(((item) => {
                return (
                    <OurBestSellers
                        key={item.id}
                        id={item.id}
                        title={item.name}
                        price={item.price}
                        image={item.primaryImage}
                        product_category={item.product_category}
                    />
                )
            }))}
        </div>
    )
}

export default Products;
