// Import necessary Redux dependencies
import { configureStore } from "@reduxjs/toolkit";

// Import your slices
import cartReducer from './redux-state/CartState';
import ordersReducer from './redux-state/OrdersState';

// Create a single store with your slices
const store = configureStore({
    reducer: {
        cart: cartReducer,
        orders: ordersReducer
    }, 
    preloadedState: localStorage.getItem("cartOrderState")
        ? JSON.parse(localStorage.getItem("cartOrderState"))
        : {}


});

// Save state to local storage whenever the state changes
store.subscribe(() => {
    localStorage.setItem("cartOrderState", JSON.stringify(store.getState()));
});

export default store;