import React from 'react'

const BsText = () => {
    return (
        <div>
            <p className=' text-4xl lh6 font-semibold text-center mt-16 mb-10'> Browse our products </p>
        </div>
    )
}

export default BsText