// Import necessary Redux dependencies
import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    orders: [],
    loading: false,
    error: null,
};


// Create a slice for the OrdersState
const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        // Action to set loading state
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        // Action to set error state
        setError: (state, action) => {
            state.error = action.payload;
        },
        // Action to add a new order
        addOrder: (state, action) => {
            state.orders.push(action.payload);
        },
        // Action to remove an order
        removeOrder: (state, action) => {
            state.orders = state.orders.filter(order => order.id !== action.payload);
        },
        // Action to clear all orders
        clearOrders: (state) => {
            state.orders = [];
        },
    },
});

// Export actions and reducer
export const orderActions = ordersSlice.actions;
export default ordersSlice.reducer;
