import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { cartActions } from '../redux-state/CartState'
import { useDispatch } from 'react-redux'

import { MdAdd } from "react-icons/md";
//import { GrFormSubtract } from "react-icons/gr"
import { RiSubtractFill } from "react-icons/ri"
import { Link } from 'react-router-dom';
import { Box, Button, ButtonGroup, Card, CardContent, Typography } from '@material-ui/core';
import { AuthContext } from '../App';
import { convertToHsl } from 'daisyui/src/colors/functions';
import { ControlsStrategy } from 'react-alice-carousel';


const Cart = (props) => {

  const { title, quantity, price, description, id, image, itemId, total, cartLength } = props.item;
  console.log("itemId at init: " + id);
  const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;



  const cartList = useSelector((state) => state.cart.items);
  const [state, setState] = React.useState({ counter: quantity });
  const { setCartLen, setCartItems } = useContext(AuthContext);



  const dispatch = useDispatch();


  const totalPrice = cartList.map(item => {
    return item.quantity * item.price;
  }).reduce((totalPrice, singleItemPrice) => totalPrice + singleItemPrice, 0);




  const removeItemFromCartHandler = () => {
    setState(state => ({ counter: state.counter - 1 }));
    const removeItem = async () => {
      var myHeaders = new Headers();
      myHeaders.append("userId", currentUser.id);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
      };

      const result = await fetch("http://104.196.11.202:8080/v1/item/" + id, requestOptions);
      const resultJson = await result.json();
      if (resultJson.length > 0) {
          var total = 0;
          resultJson.map((item) => {
              total += item.quantity;
          }
          );
          console.log(total);
          setCartLen(total);
          setCartItems(resultJson);
          console.log("Item removed from cart");
      }
    };

    removeItem();

  }

  const addItemToCartHandler = () => {
    console.log("itemId: "+ id);
    dispatch(
      cartActions.addItemToCart({
        id,
        price,
        title,
        image,
      })
    );
    const addItem = async () => {
      var myHeaders = new Headers();
      myHeaders.append("userId", currentUser.id);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
          "userId": null,
          "itemId": itemId,
          "name": title,
          "description": "The number one facial cream out there.",
          "price": price,
          "quantity": 1,
          "imageTag": image
      });

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
      };

      const result = await fetch("http://104.196.11.202:8080/v1/item", requestOptions);
      const resultJson = await result.json();
      if (resultJson.length > 0) {
          var total = 0;
          resultJson.map((item) => {
              total += item.quantity;
          }
          );
          console.log(total);
          setCartLen(total);
          setCartItems(resultJson);
          console.log("Item added to cart");
      }
    };
    addItem();
    setState(state => ({ counter: state.counter + 1 }));
  };

  const displayCounter = state.counter > 0;

  const card = (
    <React.Fragment>
    <CardContent className='card-content'>
        <Link to={`/${id}`}>
          <img src={image} className="relative  fof w-44 rounded-lg cartImg" />
        </Link>
        <div>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>{title}</b>
          </Typography>
          <Typography sx={{ fontSize: 8 }} color="text.secondary" gutterBottom>
            {description}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            ${price}
          </Typography>
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button onClick={removeItemFromCartHandler}>-</Button>
            {displayCounter && <Button>{state.counter}</Button>}
            {displayCounter && <Button onClick={addItemToCartHandler}>+</Button>}
          </ButtonGroup>
        </div>
    </CardContent>
  </React.Fragment>
  );


  return (
    <div >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Card variant="outlined">{card}</Card>
      </Box>
    </div>
  )
}

export default Cart;

