import React, {useState, useEffect, useContext} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    Box,
    FormControlLabel,
    FormControl,
    Radio,
    Tooltip,
    RadioGroup, Typography, Chip
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Alert} from '@material-ui/lab';
import {Check, Close, Info, InfoOutlined} from "@material-ui/icons";
import {merchantId} from "../constants/merchantId";
import {store} from "../productsStore/Store";

const useStyles = makeStyles({
    tableContainer: {
        maxWidth: 1200, minWidth: 1000, margin: '100px auto 100px auto',
    }, table: {
        minWidth: 750,
    }, tableHead: {
        backgroundColor: '#f5f5f5',
    }, tableCell: {
        fontWeight: 'bold',
    }, actionButton: {
        backgroundColor: '#ff4081', color: '#fff', textTransform: 'none', fontWeight: 'bold',
    }, loader: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
    }, dialogContainer11: {
        minWidth: 1000, minHeight: "15rem", display: 'flex', justifyContent: 'center', alignItems: 'center',
    }
});

const Orders = (props) => {
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    //const orderItems = useSelector(state => state.user[currentUser.id].orders);
    const [loader, setLoader] = useState(true);
    const classes = useStyles();
    const [orders, setOrders] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [returnReason, setReturnReason] = useState('Bought by mistake');
    const [loading, setLoading] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [currentOrder, setCurrentOrder] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [showSubmit, setShowSubmit] = useState(true);
    const [showDialogLoader, setShowDialogLoader] = useState(false);
    let hostUrl = 'https://api.pinch.ai';
    const [successMessage, setSuccessMessage] = useState('');
    const [isInReview, setIsInReview] = useState(false);

    const getProductName = (ids) => {
        const idsArray = ids.split(",");
        if(!idsArray[0].includes("SKU")) {
            return ids;
        }
        const items = store.filter(i => idsArray.indexOf(i.id) >= 0);
        return items.map(i => i.name).join(",");
    }
    const getStatusColor = (status) => {
        if (status === "Return Accepted") {
            return '#388e3c'
        } else if (status === "Return in Review") {
            return "#f57c00"
        } else if (status === "Return Denied") {
            return "#f50057"
        } else return "#3f51b5";
    }

    const getStatusIcon = (status) => {
        if (status === "Return Accepted") {
            return <Check style={{color: '#388e3c'}} />
        } else if (status === "Return in Review") {
            return <Info style={{color: "#f57c00"}} />
        } else if (status === "Return Denied") {
            return <Close style={{color:"#f50057" }} />
        } else return <Check style={{color: "#3f51b5"}} />;
    }

    const fetchOrders = async () => {
        setLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("userId", currentUser.id);

        const requestOptions = {
            method: 'GET', headers: myHeaders, redirect: 'follow'
        };

        const response = await fetch("http://104.196.11.202:8080/v1/order", requestOptions);
        const result = await response.json();

        // Sort the orders by timestamp
        const sortedOrders = result.sort((a, b) => b.timestamp - a.timestamp);

        setOrders(sortedOrders);
        setLoader(false);
    };

    useEffect(() => {
        // Fetch orders data from API or use dummy data based on flag
        fetchOrders();
    }, []);

    const handleReturn = (order) => {
        setOpenDialog(true);
        setOrderId(order.orderId);
        setCurrentOrder(order);
        console.log(`Initiating return for order ${order.id}`);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIsInReview(false);
        setTimeout(() => {
            fetchOrders();
            setShowSubmit(true);
            setErrorMessage('');
            setSuccessMessage('');
        }, 1000);

    };

    const [openErrorDialog, setOpenErrorDialog] = useState(false);

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const handleReturnReasonChange = (event) => {
        setReturnReason(event.target.value);
    };

    const updateOrderForReturn = async (order) => {
        setShowDialogLoader(true);
        // Update order status to 'Returned' in the backend
        const myHeaders = new Headers();
        myHeaders.append("userId", currentUser.id);
        myHeaders.append("merchantId", merchantId);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "id": currentOrder.id,
            "userId": null,
            "orderId": currentOrder.orderId,
            "price": currentOrder.price,
            "status": currentOrder.status,
            "returnId": null,
            "product": currentOrder.product,
            "productCategory": currentOrder.productCategory,
            "policyId": currentOrder.policyId,
            "policyName": currentOrder.policyName,
            "timestamp": currentOrder.timestamp,
            "reason": returnReason
        });

        const requestOptions = {
            method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow'
        };

        fetch("http://104.196.11.202:8080/v1/order/return", requestOptions)
            .then(res => res.text())
            .then(result => {
                if (result !== "Success") {
                    console.log("Error result: " + result);
                    console.log('Failed to update order status to Returned');
                    if (result === "Return in Review") {
                        setIsInReview(true);
                    } else {
                        setErrorMessage(result)
                    }
                } else {
                    console.log(`Order ${orderId} has been returned`);
                    console.log(result);

                    setSuccessMessage(order.policyName);
                }
                setShowDialogLoader(false);
            }).catch(err => setShowDialogLoader(false))
    };

    const handleSubmitReturn = async () => {
        setShowSubmit(false);
        //setLoading(true); // Step 3: Set loading state to true
        // setShowSubmit(false);

        // try {
        //     const myHeaders = new Headers();
        //     myHeaders.append("Accept", "*/*");
        //     myHeaders.append("x-merchant-id", merchantId);
        //     myHeaders.append("Content-Type", "application/json");

        //     const raw = JSON.stringify({
        //     "orderID": orderId,
        //     "reason": returnReason,
        //     "customer": {
        //         "name": currentUser.name,
        //         "deviceFingerprints": [
        //         {
        //             "algorithm": "FingerprintJS",
        //             "fingerPrint": "230sdijad f0934rlkjs dfa"
        //         }
        //         ],
        //         "contactInformation": [
        //         {
        //             "contactType": "EMail",
        //             "address": currentUser.email
        //         },
        //         {
        //             "contactType": "ContactAddress",
        //             "addressType": "Home",
        //             "address": {
        //             "line1": "9241",
        //             "line2": "13th Ave SW",
        //             "line3": null,
        //             "line4": null,
        //             "city": "Seattle",
        //             "state": "WA",
        //             "postalCode": "98106",
        //             "country": "US",
        //             "geoCode": {
        //                 "longitude": "17.44149578930582",
        //                 "latitude": "78.39321055250865"
        //             }
        //             }
        //         },
        //         {
        //             "contactType": "IPAddress",
        //             "address": "192.168.0.1"
        //         }
        //         ]
        //     },
        //     "returnedItems": [
        //         {
        //         "product": {
        //             "identifierType": "UPC",
        //             "identifier": "H1572ZY234",
        //             "category": "Shoes"
        //         },
        //         "count": 1,
        //         "policyID": 161
        //         },
        //         {
        //         "product": {
        //             "identifierType": "UPC",
        //             "identifier": "TW276234Z",
        //             "category": "Cosmetics_body_care"
        //         },
        //         "count": 1,
        //         "policyID": 161
        //         }
        //     ]
        //     });

        //     const requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: raw,
        //     redirect: 'follow'
        //     };
        //     console.log('Before return API call');
        //     const response = await fetch(hostUrl + "/return", requestOptions)

        //     // Handle API response
        //     if (response.ok) {
        //         console.log('Return submitted successfully');
        //         const result = await response.json();
        //         console.log('Return: ' +result);
        //         if (result.eligibleItems == null) {
        //             // Show dialog box with error message
        //             console.log("Return is ineligible");

        //             setOpenErrorDialog(true);
        //             setErrorMessage("Return is ineligible");
        //         } else {
        //             // Please Please Please
        //             // Add removeOrder
        //             console.log('Return: ' + result.returnID);
        //             console.log('Return submitted successfully');
        //         }

        //     } else {
        //         console.log('Failed to submit return');
        //     }
        // } catch (error) {
        //     console.log('Error occurred while submitting return:', error);
        // }
        updateOrderForReturn(currentOrder);

        //setLoading(false); // Step 5: Set loading state to false
        // setOpenDialog(false);
    };

    return (<div>
            {loader ? <Box className='emptyCartMainParent text-center relative top-20 h-full'>
                <CircularProgress size={100}/>
            </Box> : <div>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                <TableCell className={classes.tableCell}>Order ID</TableCell>
                                <TableCell className={classes.tableCell}>Delivered On</TableCell>
                                <TableCell className={classes.tableCell}>Products</TableCell>
                                <TableCell className={classes.tableCell}>Price</TableCell>
                                <TableCell className={classes.tableCell}>Status</TableCell>
                                <TableCell className={classes.tableCell}>Return</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((order) => (<TableRow key={order.id}>
                                <TableCell>{order.id}</TableCell>
                                <TableCell>{new Date(order.timestamp).toLocaleDateString()}</TableCell>
                                <TableCell>{getProductName(order.product)}</TableCell>
                                <TableCell>{order.price}</TableCell>
                                <TableCell>
                                    <Chip style={{
                                        background: "#fff",
                                        border: "1px solid",
                                        fontSize: "0.875rem",
                                        borderColor: getStatusColor(order.status),
                                        color: getStatusColor(order.status)
                                    }} label={order.status} icon={getStatusIcon(order.status)} />
                                    {order.status === "Return Denied" && (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography>Your return request is declined. Our records indicate an
                                                    extremely high rate of returns or abusive returns in the past. We
                                                    regularly monitor return activity for abuse and reserves the right
                                                    to limit returns or exchanges in all instances.</Typography>
                                            </React.Fragment>
                                        } arrow>
                                            <InfoOutlined style={{
                                                marginLeft: "1rem",
                                                color: '#f50057'
                                            }}/>
                                        </Tooltip>
                                    )}
                                    {order.reviewed && order.status === "Return Accepted" && (
                                        <Tooltip title={
                                            <React.Fragment>
                                                <Typography>Your return request has been accepted. You can return your product for a different product, or a refund to the original payment method within 14 days of receiving your return.</Typography>
                                            </React.Fragment>
                                        } arrow>
                                            <InfoOutlined style={{
                                                marginLeft: "1rem",
                                                color: '#388e3c'
                                            }}/>
                                        </Tooltip>
                                    )}

                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={order.status !== "Delivered"}
                                        className={classes.actionButton + ' action-button'}
                                        onClick={() => handleReturn(order)}
                                    >
                                        Return Items
                                    </Button>
                                </TableCell>
                            </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog open={openDialog} onClose={handleCloseDialog} className={classes.dialogContainer11}>
                    <DialogContent>
                        {showDialogLoader && (<div className={classes.loader}>
                            <CircularProgress/>
                        </div>)}
                        {!showDialogLoader && (successMessage || errorMessage) ? (<div>
                            {successMessage ?
                                <Alert severity="success"><b><h4>Your return has been accepted.</h4></b> <br/>
                                    <i>{successMessage}</i></Alert> :
                                <Alert severity="error"><b><h4>Your return has been declined.</h4></b> <br/>
                                    <i>{errorMessage}</i></Alert>}
                        </div>) : isInReview ? (
                                <Alert severity="warning"><b><h4>Your return request is being processed.</h4></b> <br/>
                                    <i>We will get back to you shortly on the status of your return. </i></Alert>
                            ) :
                            !showDialogLoader && (<FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Bought by mistake"
                                    name="radio-buttons-group"
                                    value={returnReason}
                                    onChange={handleReturnReasonChange}
                                >
                                    <FormControlLabel value="Bought by mistake" control={<Radio/>}
                                                      label="Bought by mistake"/>
                                    <FormControlLabel value="Better price available" control={<Radio/>}
                                                      label="Better price available"/>
                                    <FormControlLabel value="Performance or quality not adequate" control={<Radio/>}
                                                      label="Performance or quality not adequate"/>
                                    <FormControlLabel value="Incompatible or not useful" control={<Radio/>}
                                                      label="Incompatible or not useful"/>
                                    <FormControlLabel value="Product damaged, but shipping box O" control={<Radio/>}
                                                      label="Product damaged, but shipping box OK"/>
                                    <FormControlLabel value="Item arrived too late" control={<Radio/>}
                                                      label="Item arrived too late"/>
                                    <FormControlLabel value="Missing parts or accessories" control={<Radio/>}
                                                      label="Missing parts or accessories"/>
                                    <FormControlLabel value="Both product and shipping box damaged"
                                                      control={<Radio/>}
                                                      label="Both product and shipping box damaged"/>
                                    <FormControlLabel value="Wrong item was sent" control={<Radio/>}
                                                      label="Wrong item was sent"/>
                                    <FormControlLabel value="Item defective or doesn't work" control={<Radio/>}
                                                      label="Item defective or doesn't work"/>
                                    <FormControlLabel value="Received extra item I didn't buy (no refund needed)"
                                                      control={<Radio/>}
                                                      label="Received extra item I didn't buy (no refund needed)"/>
                                    <FormControlLabel value="No longer needed" control={<Radio/>}
                                                      label="No longer needed"/>
                                    <FormControlLabel value="Didn't approve purchase" control={<Radio/>}
                                                      label="Didn't approve purchase"/>
                                    <FormControlLabel value="Inaccurate website description" control={<Radio/>}
                                                      label="Inaccurate website description"/>
                                </RadioGroup>
                            </FormControl>)}

                    </DialogContent>
                    {!showDialogLoader && (
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Close
                            </Button>
                            {showSubmit && (<Button onClick={handleSubmitReturn} color="primary">
                                Submit
                            </Button>)}
                        </DialogActions>
                    )}
                </Dialog>

                {loading && ( // Step 4: Show loader conditionally
                    <div className={classes.loader}>
                        <CircularProgress/>
                    </div>)}

                {/* <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
                <DialogTitle>Oops!</DialogTitle>
                <DialogContent>
                    <Typography color="error">{errorMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    {
                        showSubmit && (
                            <Button onClick={handleSubmitReturn} color="primary">
                                Submit
                            </Button>
                        )
                    }
                    <Button onClick={handleCloseErrorDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>

            </Dialog> */}


            </div>}
        </div>

    );
};

export default Orders;
