import './App.css';
import { useState, createContext } from 'react';
import NavBar from './components/NavBar';
import SimpleSlider from './components/HeroCarousel';
import BsText from './components/BsText';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import SinglePage from "./components/SinglePage";
import Products from './components/Products';
import CartHold from './components/CartHold';
import Orders from './components/Orders';
import LoginPage from './components/LoginPage';
import { Navigate } from 'react-router-dom';
import {Category} from "./components/Category";

const AuthContext = createContext({});

function App() {
    // Initialize state for login status and current user
    const [isLoggedIn, setIsLoggedIn] = useState(false); // PLEASE CHANGE THIS TO FALSE
    const [currentUser, setCurrentUser] = useState(null);
    const [cartLen, setCartLen] = useState(0);
    const [cartItems, setCartItems] = useState([]);

    const populateCartLen = (userId) => {
      const fetchCartItems = async () => {
        var myHeaders = new Headers();
        myHeaders.append("userId", userId);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        const result = await fetch("http://104.196.11.202:8080/v1/item", requestOptions);
        const resultJson = await result.json();
        if (resultJson.length > 0) {
            var total = 0;
            resultJson.map((item) => {
                total += item.quantity;
            });
            setCartItems(resultJson);
            setCartLen(total);
        }
        console.log(resultJson);
        // setLoader(false);
    };
    fetchCartItems();
    }
  
    // Function to handle login
    const login = (email, password) => {
      // console.log(user);
      const loginUser = async () => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        const result = await fetch("http://104.196.11.202:8080/v1/user/login?email=" + email + "&password=" + password, requestOptions);

        const resultJson = await result.json();
        console.log(resultJson);
        if (resultJson.name) {
          console.log(result + " - User logged in successfully")
          setCurrentUser(resultJson);
          console.log("User set");
          setIsLoggedIn(true);
          localStorage.setItem('user-id', JSON.stringify(resultJson));
          populateCartLen(resultJson.id);
          return true;
        }
        return false;
      }
      
      return loginUser();
    };
  
    // Function to handle logout
    const logout = () => {
      setCurrentUser(null);
      setIsLoggedIn(false);
      localStorage.removeItem('user-id');
    };

  return (
    <div className={`App`}>
    <AuthContext.Provider value={{ isLoggedIn, currentUser, login, logout, cartLen, setCartLen, populateCartLen, cartItems, setCartItems }}>
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={
                    localStorage.getItem('user-id') ? (
                      <>
                        <NavBar />
                        <SimpleSlider />
                        <BsText />
                        <Products />
                      </>
                    ) : (
                      <Navigate to="/login" />
                    )    
          } />
          <Route path='/:id' exact element={
            localStorage.getItem('user-id') ? (
              <>
                <NavBar />
                <SinglePage />
              </>
            ) : (
              <Navigate to="/login" />
            )
          } />
           <Route path="/category/:id" element={
               localStorage.getItem('user-id') ? (
                   <>
                       <NavBar />
                       <Category />
                   </>
               ) : (
                   <Navigate to="/login" />
               )
           } />
          <Route path='/cart' exact element={
            localStorage.getItem('user-id') ? (
              <>
                <NavBar />
                <CartHold />
              </>
            ) : (
              <Navigate to="/login" />
            )
          } />
          <Route path='/orders' exact element={
            localStorage.getItem('user-id') ? (
              <>
                <NavBar />
                <Orders />
              </>
            ) : (
              <Navigate to="/login" />
            )
          } />
          <Route path='/login' element={<><LoginPage /></>} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider> 
    </div>
  );
}

export default App;

// Export AuthContext for use in other components
export { AuthContext };
